import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { useEffect } from 'react';

import { type Block } from '@lp-lib/game';
import { fromAPIBlockTypes } from '@lp-lib/game/src/api-integration';

import { BlockDetail, BlockEditorStore } from '../components/RoutedBlock';
import { useInstance } from '../hooks/useInstance';
import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';
import { useSnapshot } from '../utils/valtio';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { blockId } = action.params;
  if (!blockId) throw new Response('Not Found', { status: 404 });

  const resp = await tokenWithRedirect(
    () => apiService.block.getBlock(blockId),
    action.request.url,
    { admin: true }
  );

  const block = fromAPIBlockTypes(resp.data.block);

  return {
    block,
  };
}

export const Component = () => {
  const data = useLoaderData<typeof clientLoader>();

  const store = useInstance(() => new BlockEditorStore());

  useEffect(() => {
    store.setBlocks(data.block);
  }, [data.block, store]);

  const block = useSnapshot(store.state).blocks.find(
    (b) => b.id === data.block.id
  ) as Block | undefined;

  if (!block) return null;

  return <BlockDetail store={store} block={block} />;
};
