import { useLocation, useNavigate } from '@remix-run/react';
import noop from 'lodash/noop';
import sum from 'lodash/sum';
import { useState } from 'react';
import useSWRImmutable from 'swr/immutable';

import { type DtoBlockDataEntry } from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';
import { MediaFormatVersion } from '@lp-lib/media';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useInstance } from '../../hooks/useInstance';
import { apiService } from '../../services/api-service';
import { MediaUtils } from '../../utils/media';
import {
  type BlockEditorStore,
  BlockEditorStoreProvider,
} from '../Game/BlockEditorStore';
import { BlockVoteUtils } from '../Game/Blocks';
import {
  BlockEditor,
  useOndRecordingDetails,
} from '../Game/Blocks/Common/Editor';
import { type BlockEditorOndRecordingDetails } from '../Game/Blocks/Common/Editor/OndRecordingDetails';
import { BlockChoiceMap, BlockKnifeUtils } from '../Game/Blocks/Shared';
import { GameUtils } from '../Game/GameUtils';
import { LPLogo } from '../icons/LPLogo';
import { OndIcon } from '../icons/OndIcon';
import { RefreshIcon } from '../icons/RefreshIcon';
import { ScoreboardIcon } from '../icons/ScoreboardIcon';
import { Loading } from '../Loading';

export function Header(props: {
  block: Block;
  isSavingChanges: boolean;
  onClose: () => void;
}) {
  const { block, isSavingChanges, onClose } = props;

  const sunmary = BlockKnifeUtils.Summary(block);
  const mediaUrl =
    MediaUtils.PickMediaUrl(sunmary.coverMedia, {
      priority: [MediaFormatVersion.SM],
      videoThumbnail: 'first',
    }) ?? null;

  return (
    <div
      className={`w-full h-20 bg-admin-red px-5 border-secondary border-b 
      flex items-center justify-between flex-shrink-0`}
    >
      <div className={`flex items-center gap-5`}>
        <LPLogo type='admin' />
        <div
          className={`text-xl text-white flex flex-row justify-start items-center`}
        >
          <div className='w-19 mr-4'>
            {mediaUrl ? (
              <img
                className='w-full h-full object-cover'
                src={mediaUrl}
                alt='cover'
              />
            ) : (
              <div className='w-full h-full bg-secondary' />
            )}
          </div>
          <div className='flex flex-col'>
            <p className={`text-white text-xl font-medium truncate max-w-100`}>
              {sunmary.title || BlockChoiceMap[block.type].primary}
            </p>
          </div>
        </div>
      </div>

      <div className={`flex items-center gap-5`}>
        <p className='text-secondary text-sms'>
          {isSavingChanges ? 'Saving changes...' : 'Changes saved'}
        </p>
        <button
          onClick={onClose}
          className='btn-secondary w-34 h-10 flex flex-row justify-center items-center'
          disabled={isSavingChanges}
          type='button'
        >
          {isSavingChanges ? <Loading /> : 'Done'}
        </button>
      </div>
    </div>
  );
}

type BlockDetailTabName = 'editor' | 'recording' | 'data';

type BlockDetailTab = {
  name: BlockDetailTabName;
  label: string;
  icon?: JSX.Element;
};

function BlockTabs(props: {
  activeTabName: BlockDetailTabName;
  setActiveTabName: (next: BlockDetailTabName) => void;
}) {
  const tabs = useInstance<BlockDetailTab[]>(() => [
    { name: 'editor', label: 'Editor' },
    {
      name: 'recording',
      label: 'Recording',
      icon: <OndIcon className='w-5 h-5' />,
    },
    { name: 'data', label: 'Data' },
  ]);
  return (
    <div className='flex text-white gap-1'>
      {tabs.map((tab) => (
        <button
          key={tab.name}
          type='button'
          className={`flex items-center justify-center gap-1 w-42 h-9 text-sms font-medium ${
            tab.name === props.activeTabName ? 'bg-secondary' : 'bg-modal'
          }`}
          onClick={() => props.setActiveTabName(tab.name)}
        >
          {tab.icon}
          {tab.label}
        </button>
      ))}
    </div>
  );
}

function BlockRecording(
  props: { block: Block } & BlockEditorOndRecordingDetails
) {
  return (
    <div className='p-5 rounded-xl bg-dark-gray flex flex-row'>
      {props.block.recording ? (
        props.details
      ) : (
        <h1 className='text-white'>No Recording</h1>
      )}
    </div>
  );
}

function formatDatetime(
  datetime: string,
  timeZone = 'America/New_York'
): string {
  const date = new Date(datetime);
  const dateFormatted = new Intl.DateTimeFormat('en-US', {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);
  const timeFormatted = new Intl.DateTimeFormat('en-US', {
    timeZone,
    timeStyle: 'short',
  }).format(date);
  return `${dateFormatted} at ${timeFormatted}`;
}

function BlockDataItem(props: { label: string; value: string | number }) {
  return (
    <div className='flex items-center gap-1'>
      <p className='text-base font-bold'>{props.label}:</p>
      <p className='text-base font-medium'>{props.value}</p>
    </div>
  );
}

function BlockData(props: { block: Block }) {
  const { block } = props;
  const [data, setData] = useState<DtoBlockDataEntry | undefined>(undefined);
  const { isLoading } = useSWRImmutable(
    `/blocks/${block.id}/refresh-data`,
    async () => {
      const resp = await apiService.block.getData(block.id);
      setData(resp.data.entry);
    }
  );

  const {
    call: refresh,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(async () => {
    const resp = await apiService.block.refreshData(block.id);
    setData(resp.data.entry);
  });

  if (isLoading) return <Loading />;

  const upvoteCount = data?.data.upvoteCount ?? 0;
  const downvoteCount = data?.data.downvoteCount ?? 0;
  const upvotesPercentage =
    upvoteCount / Math.max(upvoteCount + downvoteCount, 1);

  const downvoteReasonStats = data?.data.downvoteReasonStats ?? {};
  const downvoteReasonCount = sum(Object.values(downvoteReasonStats));

  return (
    <div className='p-5 rounded-xl bg-dark-gray flex flex-col gap-8 text-white'>
      <div className='flex items-center text-2xl font-medium gap-2'>
        <ScoreboardIcon className='w-6.5 h-6.5 fill-current' />
        <p>Block Data</p>
      </div>
      <div className='flex gap-3'>
        <p>
          Last Refreshed At:{' '}
          {data?.updatedAt ? formatDatetime(data.updatedAt) : 'Unknown'}
        </p>

        {isRunning ? (
          <Loading text='' imgClassName='w-4 h-4' />
        ) : (
          <button type='button' className='btn' onClick={() => refresh()}>
            <RefreshIcon className='w-4 h-4 fill-current' />
          </button>
        )}
      </div>
      <div className='flex flex-col gap-3'>
        <div>Summary:</div>
        <section className='flex flex-col gap-1'>
          <BlockDataItem
            label='Total Block Plays'
            value={data?.data.totalPlayedCount ?? 0}
          />
          <BlockDataItem label='Upvotes' value={upvoteCount} />
          <BlockDataItem label='Downvotes' value={downvoteCount} />
          <BlockDataItem
            label='% of Votes that are Upvotes'
            value={`${upvotesPercentage.toFixed(2)}%`}
          />
        </section>
        <section className='flex flex-col gap-1'>
          <div>Downvote Reasons:</div>
          {Object.entries(downvoteReasonStats).map(([reason, count]) => (
            <BlockDataItem
              label={
                BlockVoteUtils.GetOptions().find(
                  (o) => o.value === parseInt(reason)
                )?.label ?? 'Unknown'
              }
              value={`${count} - ${(
                count / Math.max(downvoteReasonCount, 1)
              ).toFixed(2)}%`}
            />
          ))}
        </section>
      </div>
    </div>
  );
}

export function BlockDetail(props: {
  store: BlockEditorStore;
  block: Block;
}): JSX.Element {
  const { block } = props;
  const [isSavingChanges, setSavingChanges] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTabName, setActiveTabName] =
    useState<BlockDetailTabName>('editor');

  const recordingDetails = useOndRecordingDetails(
    block,
    GameUtils.DeriveOndPlaybackVersionFromBlocks([block]),
    noop
    // TODO: deleteConfirmConfig - we need to check if a block is used in a game
  );

  const onClose = () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate('/admin/blocks');
    }
  };

  return (
    <div className='w-screen h-screen flex flex-col'>
      <Header
        block={block}
        isSavingChanges={isSavingChanges}
        onClose={onClose}
      />
      <div className='w-full flex justify-center scrollbar overflow-y-auto'>
        <div className='w-full min-w-0 flex flex-col gap-4 mx-40 my-4 h-fit-content'>
          <BlockTabs
            activeTabName={activeTabName}
            setActiveTabName={setActiveTabName}
          />
          {activeTabName === 'editor' && (
            <BlockEditorStoreProvider store={props.store}>
              <BlockEditor block={block} setSavingChanges={setSavingChanges} />
            </BlockEditorStoreProvider>
          )}
          {activeTabName === 'recording' && (
            <BlockRecording block={block} {...recordingDetails} />
          )}
          {activeTabName === 'data' && <BlockData block={block} />}
        </div>
      </div>
      {recordingDetails.warningModal}
    </div>
  );
}
